<template>
  <div class>
    <v-row>
      <v-col cols="12" md="4" class="title-col">
        <div class="title">
          <h1>{{ $t("Edit Notification") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" md="4" sm="12" lg="4"
          ><v-card class="card">
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Subject") }}</label>
                <v-text-field
                  v-model="data.subject"
                  solo
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  @focus="onFoucs"
                ></v-text-field>
                <div v-if="validation_errors.subject" class="vertical-space">
                  <p
                    class="error-txt red--text"
                    v-for="(error, index) in validation_errors.subject"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <label>{{ $t("Message") }}</label>
                <v-textarea
                  v-model="data.message"
                  solo
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength225,
                  ]"
                  @focus="onFoucs"
                ></v-textarea>
                <div v-if="validation_errors.message" class="vertical-space">
                  <p
                    class="error-txt red--text"
                    v-for="(error, index) in validation_errors.message"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col> </v-row
            ><v-row>
              <v-col sm="12">
                <label>{{ $t("Link") }}</label>
                <v-text-field
                  solo
                  append-icon="mdi-link"
                  v-model="data.link"
                  @focus="onFoucs"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Platform") }}</label>
                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                      :label="$t('Web')"
                      value="web"
                      v-model="data.platform"
                      :rules="[validationRules.required]"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      :label="$t('Mobile Device')"
                      value="mobile"
                      v-model="data.platform"
                      :rules="[validationRules.required]"
                    ></v-checkbox>
                  </v-col>
                  <div v-if="validation_errors.platform" class="vertical-space">
                    <p
                      class="error-txt red--text"
                      v-for="(error, index) in validation_errors.platform"
                      :key="index"
                      @focus="onFocus()"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-row>
              </v-col>
            </v-row></v-card
          ></v-col
        >
        <v-col cols="12" md="4" sm="12" lg="4"
          ><v-card class="card">
            <div max-height="80">
              <v-row>
                <v-col sm="12">
                  <label>{{ $t("Time") }}</label>
                  <v-row style="margin-top: -12px">
                    <v-col sm="6" md="3">
                      <v-btn
                        :rules="[validationRules.required]"
                        class="transparent"
                        @click="ChooseTimeType('now')"
                        :disabled="data.time_type == 'now' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/now-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{ $t("Now") }}</label>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <v-btn
                        :rules="[validationRules.required]"
                        @click="ChooseTimeType('schedule')"
                        class="transparent"
                        :disabled="data.time_type == 'schedule' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/schedule-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Schedule")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div
                        v-if="validation_errors.time_type"
                        class="vertical-space"
                      >
                        <p
                          class="error-txt red--text"
                          v-for="(error, index) in validation_errors.time_type"
                          :key="index"
                          @focus="onFocus()"
                        >
                          {{ error }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div
              v-show="data.time_type == 'schedule'"
              style="margin-top: -25px"
            >
              <v-row>
                <v-col sm="12">
                  <label>{{ $t("Date/Time Start") }}</label>
                  <v-datetime-picker
                    v-model="data.start_date"
                    dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm:ss"
                    class="datetime"
                    @focus="onFoucs"
                  ></v-datetime-picker>
                  <div
                    v-if="validation_errors.start_date"
                    class="vertical-space"
                  >
                    <p
                      class="error-txt red--text"
                      v-for="(error, index) in validation_errors.start_date"
                      :key="index"
                      @focus="onFocus()"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <!-- <label
                  v-if="data.time_type == 'schedule' && data.start_date == ''"
                  class="label_error"
                  >This field is required</label
                > --> </v-row
              ><v-row>
                <v-col sm="12">
                  <label>{{ $t("Repeat") }}</label>
                  <v-row>
                    <v-col sm="6" md="3">
                      <v-btn
                        class="transparent"
                        :disabled="data.repeat == 'once' ? true : false"
                        @click="ChooseRepeat('once')"
                      >
                        <v-img
                          src="../../../assets/icons/once-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Once")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <v-btn
                        class="transparent"
                        @click="ChooseRepeat('daily')"
                        :disabled="data.repeat == 'daily' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/daily-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Daily")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <v-btn
                        class="transparent"
                        @click="ChooseRepeat('weekly')"
                        :disabled="data.repeat == 'weekly' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/weekly-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Weekly")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <v-btn
                        class="transparent"
                        @click="ChooseRepeat('monthly')"
                        :disabled="data.repeat == 'monthly' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/monthly-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Monthly")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <v-btn
                        class="transparent"
                        @click="ChooseRepeat('yearly')"
                        :disabled="data.repeat == 'yearly' ? true : false"
                      >
                        <v-img
                          src="../../../assets/icons/yearrly-01.png"
                          width="20"
                          height="35"
                        ></v-img>
                      </v-btn>
                      <div style="text-align: center !important">
                        <label class="transparent_label">{{
                          $t("Yearly")
                        }}</label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div
                        v-if="validation_errors.repeat"
                        class="vertical-space"
                      >
                        <p
                          class="error-txt red--text"
                          v-for="(error, index) in validation_errors.repeat"
                          :key="index"
                          @focus="onFocus()"
                        >
                          {{ error }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <label
                    v-if="data.time_type == 'schedule' && data.repeat == ''"
                    class="label_error"
                    >This field is required</label
                  > -->
                </v-col>
              </v-row>
              <div v-show="data.repeat != 'once'" style="margin-top: -25px">
                <v-row>
                  <v-col sm="12">
                    <label>{{ $t("Date/Time End") }}</label>
                    <v-datetime-picker
                      v-model="data.end_date"
                      dateFormat="yyyy-MM-dd"
                      timeFormat="HH:mm:ss"
                      @focus="onFoucs"
                    ></v-datetime-picker>
                    <div
                      v-if="validation_errors.end_date"
                      class="vertical-space"
                    >
                      <p
                        class="error-txt red--text"
                        v-for="(error, index) in validation_errors.end_date"
                        :key="index"
                        @focus="onFocus()"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </v-col>
                  <!-- <label
                    v-if="
                      data.time_type == 'schedule' &&
                        data.repeat != 'once' &&
                        data.end_date == ''
                    "
                    class="label_error"
                    >This field is required</label
                  > -->
                </v-row>
              </div>
            </div>
          </v-card></v-col
        >
        <v-col cols="12" md="4" sm="12" lg="4"
          ><v-card class="card">
            <div max-height="300">
              <v-row>
                <v-col sm="12">
                  <label>{{ $t("Audience") }}</label>
                  <v-row class="audience-layout">
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="1">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('1') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Super-Admin.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Super-admin")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="6">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('6') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Accountant.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Accountant")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="2">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('2') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Coordinator.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Coordinator")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="3">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('3') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Teacher.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Teacher")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="5">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('5') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Parent.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Parent")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="4">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('4') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Student.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Student")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <!-- admin -->
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="9">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('9') ? 'active' : ''
                                  "
                                  src="../../../assets/images/Admin.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("Admin")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col sm="6" md="3">
                      <div class="ChangeRole">
                        <div class="radioGroupContainer">
                          <v-checkbox v-model="data.roles" value="all_users">
                            <template slot="label">
                              <div>
                                <v-img
                                  class="margin_left_image"
                                  :class="
                                    data.roles.includes('all_users')
                                      ? 'active'
                                      : ''
                                  "
                                  src="../../../assets/icons/all-users-01.png"
                                ></v-img>
                              </div>
                              <label class="roleLabelCheck" for>{{
                                $t("All Users")
                              }}</label>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <div v-if="validation_errors.roles" class="vertical-space">
                    <p
                      class="error-txt red--text"
                      v-for="(error, index) in validation_errors.roles"
                      :key="index"
                      @focus="onFocus()"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Nationality") }}</label>
                <v-autocomplete
                  :items="nationalites"
                  item-text="name"
                  item-value="id"
                  solo
                  multiple
                  chips
                  v-model="data.nationalites"
                  :disabled="data.roles.length > 0"
                  :rules="[CheckNatRoles()]"
                >
                </v-autocomplete>
                <div
                  v-if="validation_errors.nationalities"
                  class="vertical-space"
                >
                  <p
                    class="error-txt red--text"
                    v-for="(error, index) in validation_errors.nationalites"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row></v-card
          ></v-col
        >
      </v-row>
    </v-form>
    <v-row>
      <v-col sm="12" class="buttons">
        <v-btn class="modal-btn-cancel cancelBtn paddingClass" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn paddingClass"
          @click="SavePushNotification"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../../mixins/validationMixin";
export default {
  name: "EditPushNotification",
  data() {
    return {
      loading: false,
      valid: false,
      data: {
        subject: "",
        message: "",
        link: "",
        platform: [],
        time_type: "",
        start_date: "",
        end_date: "",
        repeat: "",
        roles: [],
        nationalites: [],
      },
      nationalites: [],
      validation_errors: [],
      id: "",
    };
  },
  mixins: [validationMixin],
  watch: {
    "data.roles": {
      handler() {
        if (
          this.data.roles.includes("all_users") == true &&
          this.data.roles.length >= 0 &&
          this.data.roles.length < 6
        ) {
          // this.images = [];
          this.data.roles = ["1", "2", "3", "4", "5", "6", "9"];
        } else if (
          this.data.roles.includes("all_users") == true &&
          this.data.roles.length >= 6
        ) {
          this.data.roles = [];
        }
        if (this.data.roles.length > 0) {
          //console.log("medooo shreef");
          this.data.nationalites = [];
        }
      },
    },
  },
  methods: {
    onFoucs() {
      this.validation_errors = [];
    },
    close() {
      this.$router.push(
        {
          path: "/viewPushNotification/" + this.id,
        },
        () => {}
      );
    },
    CheckNatRoles() {
      if (this.data.roles.length == 0 && this.data.nationalites.length == 0) {
        return "This field is required";
      }
      return true;
    },
    SavePushNotification() {
      // console.log(this.valid);
      if (this.valid == false) {
        this.validate();
      } else {
        // this.data.start_date = new Date(this.data.start_date);
        // this.data.end_date = new Date(this.data.end_date);
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/push_notifications/update/" + this.id,
            this.data,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.$router.push(
                {
                  path: "/workingdays?tab=0&page=1",
                },
                () => {}
              );
            } else {
              this.validation_errors = response.data.status.validation_errors;
            }
          });
      }
    },
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.nationalites = response.data.data;
      });
    },
    ChooseTimeType(type) {
      this.data.time_type = type;
      this.onFoucs();
      // console.log(this.time_type == "now");
    },
    ChooseRepeat(repeat) {
      this.data.repeat = repeat;
      if (repeat == "once") {
        this.data.end_date = "";
      }
      this.onFoucs();
    },
    GetPushNotification() {
      // this.data.start_date = new Date(this.data.start_date);
      // this.data.end_date = new Date(this.data.end_date);
      axios
        .get(
          this.getApiUrl + "/push_notifications/edit/" + this.id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.data = response.data.data;
            // console.log(response.data.data);
            // this.data.start_date = new Date(this.data.start_date);
            this.ChooseTimeType(this.data.time_type);
          }
        });
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.getCountries();
    this.GetPushNotification();
  },
};
</script>
<style scoped lang="scss">
@import "../../../styles/_variables.scss";
@import "../../../styles/_modals.scss";

label {
  color: #8d8a8a;
  font-size: 13px;
  font-weight: bold;
}
.label_error {
  font-size: 14px !important;
  font-weight: normal;
  color: #ff5252 !important;
  margin-left: 20px;
  margin-top: -20px;
}
// .card {
//   height: 670px;
// }
.buttons {
  text-align: right;
}
.paddingClass {
  margin: 3px;
}
.transparent {
  background-color: transparent;
}
.transparent_label {
  color: $main-color;
  font-size: 11px;
  font-weight: normal;
  text-align: center !important;
}
.time_type_active {
  background-color: #b9cbff;
}
</style>
